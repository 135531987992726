<template>
  <div class="photoUpload">
    <div class="tab-list">
      <div
        :class="activenum == item.id ? 'activeclass' : ''"
        v-for="(item, index) in tablist"
        :key="index"
        @click="changetab(item.path)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <el-card class="box-card">
      <div class="bc-tit font18">公司相册</div>
      <div class="imgshow">
        <div class="imgshowmain" v-for="(imgs, index) in imglist" :key="index">
          <img :src="imgs" />

          <img
            class="el-icon-error"
            src="../../assets/images/common/sahnchu.png"
            @click="delImg(index)"
          />
        </div>
        <div class="upbox">
          <el-upload
            class="upload-demo"
            drag
            accept=".png,.bmp,.webp,.jpg,.jpeg,.gif"
            :on-success="handleSuccessPic"
            action="https://freemen.work/user/file/upload/"
            multiple
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__text">请选择图片</div>
          </el-upload>
        </div>
      </div>
      <div class="bc-tit font18">公司视频</div>
      <div class="imgshow">
        <div
          class="imgshowmain"
          v-for="(imgs, index) in videolist"
          :key="index"
        >
          <video
            v-bind:src="imgs"
            class="avatar video-avatar"
            controls="controls"
          >
            您的浏览器不支持视频播放
          </video>
          <div class="videoBox">
            <img
              class="bofang"
              src="../../assets/images/common/bofang-img.png"
              @click="videoshow(imgs)"
            />
            <!-- <img :src="imgs+'?x-oss-process=video/snapshot,t_1,f_jpg,m_fast,ar_auto'" /> -->
            <img
              class="el-icon-error"
              src="../../assets/images/common/sahnchu.png"
              @click="delVideo(index)"
            />
          </div>
        </div>
        <div class="upbox" v-if="videolist.length == 0">
          <el-upload
            class="upload-demo"
            drag
            accept=".mp4,.avi"
            :on-success="handleSuccessVid"
            action="https://freemen.work/user/file/upload/"
            multiple
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__text">请选择视频</div>
          </el-upload>
        </div>
      </div>
      <div class="footer-btn">
        <el-button type="primary" class="btn-next" @click="save"
          >保存</el-button
        >
      </div>
    </el-card>
    <!-- 视频详情 -->
    <videodetail></videodetail>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import videodetail from "/src/components/enterprise/videodetail.vue"; //视频详情
export default {
  components: {
    videodetail,
  },
  data() {
    return {
      tablist: [
        { name: "企业基本信息", id: 1, path: "/basicinfo" },
        { name: "公司相册", id: 2, path: "" },
        { name: "考勤设置", id: 3, path: "/worktime" },
        { name: "职位等级设置", id: 4, path: "/positionlevel" },
      ],
      activenum: 2,
      updialogVisible: false,
      imglist: [],
      imgurl: "",
      videolist: [
        {
          id: 1,
          url: "http://vjs.zencdn.net/v/oceans.mp4",
          fengmian:
            "https://img0.baidu.com/it/u=2619691961,843226653&fm=253&fmt=auto&app=120&f=GIF?w=567&h=300",
        },
      ],
      imgConfig: [],
      vidConfig: [],
      config: {
        id: "",
        album: "",
        resUrl: "",
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  activated() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.imglist = [];
      this.imgConfig = [];
      this.videolist = [];
      this.vidConfig = [];
      this.$api.getUserInfo("get").then((res) => {
        this.config.id = res.data.company.id;
        this.config.album = res.data.company.album;
        this.config.resUrl = res.data.company.resUrl;
        if (res.data.company.album) {
          let arr = res.data.company.album.split(",");
          this.imglist = [];
          this.imgConfig = [];
          arr.map((item) => {
            this.imglist.push("https://freemen.work/user/file/view/" + item);
            this.imgConfig.push(item);
          });
        }
        if (res.data.company.resUrl) {
          let arr = res.data.company.resUrl.split(",");
          this.videolist = [];
          this.vidConfig = [];
          arr.map((item) => {
            this.videolist.push("https://freemen.work/user/file/view/" + item);
            this.vidConfig.push(item);
          });
        }
      });
    },
    save() {
      this.config.album = this.imgConfig.join(",");
      this.config.resUrl = this.vidConfig.join(",");
      this.$api.saveUserInfo("post", this.config).then(() => {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.getUserInfo();
      });
    },
    videoshow(url) {
      let data = {
        show: true,
        url: url,
      };
      Bus.$emit("gsvideodstuts", data);
    },
    changetab(path) {
      console.log(path);
      this.$router.push(path);
    },
    handleSuccessPic(response, file, fileList) {
      console.log(file, fileList);
      this.imglist.push("https://freemen.work/user/file/view/" + response.data);
      this.imgConfig.push(response.data);
    },
    handleSuccessVid(response, file, fileList) {
      console.log(file, fileList);
      this.videolist.push(
        "https://freemen.work/user/file/view/" + response.data
      );
      this.vidConfig.push(response.data);
    },
    delImg(index) {
      this.imglist.splice(index, 1);
      this.imgConfig.splice(index, 1);
    },
    delVideo(index) {
      this.videolist.splice(index, 1);
      this.vidConfig.splice(index, 1);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.imgurl = file.url;
      this.updialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.tab-list {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  div {
    margin-right: 30px;
    color: #666666;
  }
  .activeclass {
    color: #111111;
    position: relative;
    font-weight: 600;
  }
  .activeclass :after {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    bottom: -6px;
    background: #00bcff;
    left: 30%;
  }
}
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 0px 30px 160px 30px;
}
/deep/.el-upload-dragger {
  width: 300px;
  height: 180px;
  border-radius: 0;
  background-color: #dddddd;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 2;
  margin-top: 13px;
  .el-icon-plus {
    font-size: 30px;
    font-weight: 600;
    color: #666666;
  }
  .el-upload__text {
    color: #666666;
  }
}

.imgts {
  color: #999999;
}
.bc-tit {
  margin: 30px 0 0 0;
  color: #111111;
}
.upbox {
  width: 300px;
  height: 180px;
}
.imgshow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 30px;
  .imgshowmain:hover {
    .el-icon-error {
      display: flex !important;
    }
  }
  .imgshowmain {
    margin-right: 30px;
    margin-top: 30px;
    position: relative;
    .video-avatar {
      width: 300px;
      height: 180px;
    }
    .video-avatar::-webkit-media-controls {
      display: none !important;
    }
    .videoBox {
      width: 300px;
      height: 180px;
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      width: 300px;
      height: 180px;
    }
    .bofang {
      position: absolute;
      width: 67px;
      height: 63px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .el-icon-error {
      width: 30px;
      height: 30px;
      top: 5px;
      right: 5px;
      position: absolute;
      font-size: 30px;
      color: #dddddd;
      display: none;
    }
  }
}
.footer-btn {
  margin-top: 60px;
  padding-left: 30px;
  /deep/ .el-button {
    border-radius: 0px;
    width: 450px;
    background: #00bcff;
    border: none;
  }
}
</style>
<style>
.photoUpload .el-upload-list {
  display: none;
}
</style>
