import { render, staticRenderFns } from "./photoalbum.vue?vue&type=template&id=ad257c90&scoped=true&"
import script from "./photoalbum.vue?vue&type=script&lang=js&"
export * from "./photoalbum.vue?vue&type=script&lang=js&"
import style0 from "./photoalbum.vue?vue&type=style&index=0&id=ad257c90&lang=less&scoped=true&"
import style1 from "./photoalbum.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad257c90",
  null
  
)

export default component.exports